import Vue from "vue";
import router from "./vue-router";
import {GridModule} from "@ts/store/modules/GridModule";
import TopNav from "@ts/components/TopNav.vue";

new Vue({
    el: "#app",
    router,
    components: {TopNav},
    created: () => {
        GridModule.loadGridCells();
    }
});
