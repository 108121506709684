




























import {Component, Vue, Watch} from 'vue-property-decorator';
import VueSlider from 'vue-slider-component'
import {GridModule} from '@ts/store/modules/GridModule';
import axios from 'axios';
import {IDateRange} from '@ts/types';

const oneDay: number = 60 * 60 * 24;

@Component({
    components: {VueSlider}
})
export default class DateRangeSelector extends Vue {
    private dateSelectorLimits = [0, 0];
    private dateRange: number[] = [0, 0];
    private interval: number = oneDay;
    private grid = GridModule;
    private isReady: boolean = false;

    private get startDate(): string {
        if (!this.dateRange[0]){
            return '';
        }
        return this.toDateTimeString(this.dateRange[0]);
    }

    private get endDate(): string {
        if (!this.dateRange[1]){
            return '';
        }
        return this.toDateTimeString(this.dateRange[1]);
    }

    async created() {
        try {
            const response = await axios.get<IDateRange>('/date-range');
            this.dateRange = [
                (new Date(response.data.startDate)).getTime() - oneDay,
                (new Date(response.data.endDate)).getTime() + oneDay
            ];
            this.dateSelectorLimits[0] = this.dateRange[0];
            this.dateSelectorLimits[1] = this.dateRange[1] + this.interval - (this.dateRange[1] - this.dateRange[0]) % this.interval;
            this.isReady = true;
        } catch (e) {
            console.error('Failed to get date range', e);
        }
    }

    @Watch('startDate')
    @Watch('endDate')
    private async onRangeChanged() {
        if (! this.isReady) {
            return;
        }
        await this.grid.loadGridCells({startDate: this.startDate, endDate: this.endDate});
    }

    private toDateTimeString(timestamp: number): string {
        const d = new Date(timestamp);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate()}`.padStart(2, '0');
        const year = `${d.getFullYear()}`;

        return `${year}-${month}-${day}`;
    };

    private toPrettyDateTimeString(timestamp: number): string {
        return (new Date(timestamp)).toDateString().slice(4);
    }
}
