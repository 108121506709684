












import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IGeoCoordinate, IGoogleMaps, IMapsOverlay} from '@ts/types';
import Overlay from '@ts/classes/GoogleMapsOverlay'
import {GridModule} from '@ts/store/modules/GridModule';

declare var google;

@Component
export default class GoogleMaps extends Vue implements IGoogleMaps {
    @Prop({required: true}) private startPosition!: IGeoCoordinate;

    private grid = GridModule;

    private map: any;
    private overlays: any[] = [];

    public async mounted() {
        this.loadGoogleMaps();
    }

    @Watch('grid.selectedCellIndex')
    private panToCell(cellIndex: number) {
        if (! this.grid.selectedCell) {
            return;
        }
        const {latitude, longitude} = this.grid.selectedCell.signs[0];
        const destination = new google.maps.LatLng(latitude, longitude);
        this.map.panTo(destination);
    }

    private loadGoogleMaps() {
        if (!google.maps.Map) {
            setTimeout(this.loadGoogleMaps, 500);
            return;
        }

        this.map = new google.maps.Map(document.getElementById('map'), {
            center: {lat: this.startPosition.latitude, lng: this.startPosition.longitude},
            zoom: 13,
            disableDefaultUI: true
        });
    }

    public placeOverlay(overlay: IMapsOverlay): void {
        this.overlays.push(new Overlay(overlay, this.map))
    }

    public clearOverlays(): void {
        while (this.overlays.length > 0) {
            const overlay = this.overlays.pop();
            overlay.setMap(null);
        }
    }
}
