



















import {Component, Vue} from 'vue-property-decorator';

interface IRoute {
    path: string;
    label: string;
}

@Component
export default class TopNav extends Vue {
    private routes: IRoute[] = [
        {
            path: '/',
            label: 'home'
        },
        {
            path: '/about',
            label: 'about'
        }
    ]

}
