














import {Component, Vue, Watch} from 'vue-property-decorator';
import {GridModule} from '../store/modules/GridModule';
import {IGeoCoordinate, IGoogleMaps, IMapsOverlay} from '@ts/types';
import GoogleMaps from '@ts/components/GoogleMaps.vue';
import ListView from "@ts/components/ListView.vue";
import GoogleStreetView from "@ts/components/GoogleStreetView.vue";
import DateRangeSelector from "@ts/components/DateRangeSelector.vue";


@Component({
    components: {DateRangeSelector, GoogleStreetView, ListView, GoogleMaps}
})
export default class CollisionDashboard extends Vue {
    private grid = GridModule;
    private kitchenerCenter: IGeoCoordinate = {
        latitude: 43.433259,
        longitude: -80.481032,
    };
    private overlays: IMapsOverlay[] = [];

    private created() {
        window.addEventListener('overlay-clicked', this.onOverlayClicked);
    }

    private onOverlayClicked(event) {
        const {index} = event.detail;
        this.grid.selectCell(index);
    }

    @Watch('grid.selectedCellIndex')
    private highlightSelectedCell(newIndex: number, oldIndex: number) {
        const formerSelection = document.getElementById(`collision-overlay-${oldIndex}`);
        if (formerSelection) {
            formerSelection.classList.remove('selected');
        }

        const newSelection = document.getElementById(`collision-overlay-${newIndex}`);
        if (newSelection) {
            newSelection.classList.add('selected');
        }
    }


    @Watch('grid.cells')
    private refreshOverlays() {
        this.map.clearOverlays();
        this.buildOverlays();
    }

    private buildOverlays() {
        this.grid.cells.forEach((gridCell, index) => {
            const {longitude, latitude} = gridCell.signs[0];

            const dotSize = 0.0002;
            const sizeIncreasePerCollision = 0.0001;

            const overlayBase: IMapsOverlay = {
                latitude,
                longitude,
                size: dotSize,
                imagePath: 'red-circle.png',
                payload: {
                    index
                }
            };

            const signOverlay: IMapsOverlay = {
                ...overlayBase,
                cssClass: 'signs-overlay',
                isClickable: false,
            };

            const collisionBubbleOverlay: IMapsOverlay = {
                ...overlayBase,
                size: dotSize + sizeIncreasePerCollision * gridCell.collisions.length,
                id: `collision-overlay-${index}`,
                cssClass: 'collision-overlay',
                isClickable: true,
            };

            this.map.placeOverlay(signOverlay);
            this.map.placeOverlay(collisionBubbleOverlay);
        });
    }

    private get map(): IGoogleMaps {
        return this.$refs.map as unknown as IGoogleMaps;
    }

}
