
























import {Component, Vue, Watch} from 'vue-property-decorator';
import {GridModule} from '@ts/store/modules/GridModule';

declare var google;
@Component
export default class GoogleStreetView extends Vue {
    private streetView: any;
    private grid = GridModule;

    private get hasNoCellSelected(): boolean {
        return this.grid.selectedCellIndex < 0;
    }

    @Watch('grid.selectedCellIndex')
    private changeStreetViewToSelectedCell(): void {
        if (! this.grid.selectedCell) {
            return;
        }
        const {latitude, longitude} = this.grid.selectedCell.signs[0];
        this.loadStreetViewAt(latitude, longitude);
    }

    private loadStreetViewAt(latitude: number, longitude: number) {
        if (!google.maps.StreetViewPanorama) {
            setTimeout(() => this.loadStreetViewAt(latitude, longitude), 500);
            return;
        }

        this.streetView = new google.maps.StreetViewPanorama(
            document.getElementById('street-view'), {
                position: {lat: latitude, lng: longitude},
            });
    }

}
