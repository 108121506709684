










import {Component, Vue} from 'vue-property-decorator';
import CollisionDashboard from '@ts/components/CollisionDashboard.vue';

@Component({
    components: {CollisionDashboard}
})
export default class HomePage extends Vue {
}
