import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import HomePage from "./views/HomePage.vue";
import AboutPage from "./views/AboutPage.vue";


Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        component: HomePage,
        path: "/"
    },
    {
        component: AboutPage,
        path: "/about"
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
