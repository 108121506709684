// Reference:
// https://developers.google.com/maps/documentation/javascript/customoverlays
import {IMapsOverlay} from "@ts/types";

declare var google;
Overlay.prototype = new google.maps.OverlayView();

/** @constructor */
function Overlay(overlay: IMapsOverlay, map) {
    const {latitude, longitude, size, imagePath, cssClass, id, payload} = overlay;
    const overlayClass = cssClass ||  "google-maps-overlay";

    const curvatureCorrection = .725588961;
    const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(latitude - size * curvatureCorrection, longitude - size),
        new google.maps.LatLng(latitude + size * curvatureCorrection, longitude + size),
    );

    // Initialize all properties.
    this.overlay_ = overlay;
    this.bounds_ = bounds;
    this.image_ = imagePath;
    this.map_ = map;
    this.overlayClass_ = overlayClass;
    this.payload_ = payload;
    this.id_ = id;
    this.isClickable_ = !! overlay.isClickable;

    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    this.div_ = null;

    // Explicitly call setMap on this overlay.
    this.setMap(map);
}

/**
 * onAdd is called when the map's panes are ready and the overlay has been
 * added to the map.
 */
Overlay.prototype.onAdd = function() {

    var div = document.createElement('div');
    div.style.borderStyle = 'none';
    div.style.borderWidth = '0px';
    div.style.position = 'absolute';

    // Create the img element and attach it to the div.
    var img = document.createElement('img');
    if (this.overlayClass_) {
        img.classList.add(this.overlayClass_);
    }
    if (this.id_) {
        img.id = this.id_;
    }
    img.src = this.image_;
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.position = 'absolute';
    div.appendChild(img);

    this.div_ = div;

    // Add the element to the "overlayLayer" pane.
    var panes = this.getPanes();
    panes.overlayLayer.appendChild(div);

    if (this.isClickable_) {
        panes.overlayMouseTarget.appendChild(div);
        google.maps.event.addDomListener(div, 'click', () => {
            window.dispatchEvent(new CustomEvent('overlay-clicked', {"detail": this.payload_}));
        });
    }
};

Overlay.prototype.draw = function() {

    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    var overlayProjection = this.getProjection();

    // Retrieve the south-west and north-east coordinates of this overlay
    // in LatLngs and convert them to pixel coordinates.
    // We'll use these coordinates to resize the div.
    var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
    var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

    // Resize the image's div to fit the indicated dimensions.
    var div = this.div_;
    div.style.left = sw.x + 'px';
    div.style.top = ne.y + 'px';
    div.style.width = (ne.x - sw.x) + 'px';
    div.style.height = (sw.y - ne.y) + 'px';
};

// The onRemove() method will be called automatically from the API if
// we ever set the overlay's map property to 'null'.
Overlay.prototype.onRemove = function() {
    this.div_.parentNode.removeChild(this.div_);
    this.div_ = null;
};


// Set the visibility to 'hidden' or 'visible'.
Overlay.prototype.hide = function() {
    if (this.div_) {
        // The visibility property must be a string enclosed in quotes.
        this.div_.style.visibility = 'hidden';
    }
};

Overlay.prototype.show = function() {
    if (this.div_) {
        this.div_.style.visibility = 'visible';
    }
};

Overlay.prototype.toggle = function() {
    if (this.div_) {
        if (this.div_.style.visibility === 'hidden') {
            this.show();
        } else {
            this.hide();
        }
    }
};

// Detach the map from the DOM via toggleDOM().
// Note that if we later reattach the map, it will be visible again,
// because the containing <div> is recreated in the overlay's onAdd() method.
Overlay.prototype.toggleDOM = function() {
    if (this.getMap()) {
        // Note: setMap(null) calls OverlayView.onRemove()
        this.setMap(null);
    } else {
        this.setMap(this.map_);
    }
};

export default Overlay;
