






















































































































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class AboutPage extends Vue {
    goTo(path: string) {
        window.location.assign(path);
    }

}
