import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {IGridCell, IGridModule} from "@ts/types";
import store from "@ts/store";
import axios from "axios";

@Module({dynamic: true, store, name: "gridModule"})
class GridModuleBase extends VuexModule implements IGridModule {
    public cells: IGridCell[] = [];
    public selectedCellIndex: number = -1;
    public isLoading: boolean = false;

    public get selectedCell(): IGridCell | null {
        if (this.selectedCellIndex === -1) {
            return null;
        }
        return this.cells[this.selectedCellIndex];
    }

    public get totalAmountOfCollisions(): number {
        return this.cells.reduce((total: number, cell: IGridCell) => {
            return total + cell.collisions.length;
        }, 0);
    }

    @Mutation
    private SET_IS_LOADING(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @Mutation
    private SET_CELLS(cells: IGridCell[]): void {
        this.cells = cells;
    }

    @Mutation
    private SET_SELECTED_CELL_INDEX(index: number): void {
        this.selectedCellIndex = index;
    }

    @Action
    public async loadGridCells(queryParameters?: { startDate?: string, endDate?: string }): Promise<void> {
        this.context.commit("SET_IS_LOADING", true);
        try {
            let queryUrl: string = "/grid-cells";
            if (queryParameters) {
                queryUrl += `?${new URLSearchParams(queryParameters)}`;
            }

            const response = await axios.get<IGridCell[]>(queryUrl);
            this.context.commit("SET_CELLS", response.data);
            this.context.commit("SET_SELECTED_CELL_INDEX", -1);
        } catch (e) {
            window.alert("Failed to get grid cells. Please try again.");
            console.error("Failed to get grid cells", e);
        }
        this.context.commit("SET_IS_LOADING", false);
    }

    @Action
    public selectCell(index: number): void {
        this.context.commit("SET_SELECTED_CELL_INDEX", index);
    }
}

export const GridModule = getModule(GridModuleBase);
