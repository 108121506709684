















import {Component, Vue, Watch} from 'vue-property-decorator';
import {GridModule} from '@ts/store/modules/GridModule';
interface HTMLElement {
    scrollIntoViewIfNeeded: () => null;
}

@Component
export default class ListView extends Vue {
    private grid = GridModule;

    @Watch('grid.selectedCellIndex')
    private scrollToItem(cellIndex: number) {
        const tab = document.getElementById(`list-item-${cellIndex}`) as any;
        try {
            tab.scrollIntoViewIfNeeded();
        } catch (e) {
            console.error(`Failed to scroll tab ${cellIndex} into view`, e);
        }
    }

}
